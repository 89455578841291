import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import './assets/style.scss';
import './assets/custom.scss';
import './assets/animated.css';
import App from './App';
import { Provider } from 'react-redux';

import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import "@rainbow-me/rainbowkit/styles.css";
import { WagmiProvider } from "wagmi";
import { zkSync, zkSyncSepoliaTestnet } from "wagmi/chains";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const config = getDefaultConfig({
	appName: "SoB Vesting Game",
	projectId: "8ddc5193bf6afc32a421b933405f2d40",
	chains: [
		// zkSync,
		zkSyncSepoliaTestnet,
	],
});

const queryClient = new QueryClient();

ReactDOM.render(
	<WagmiProvider config={config}>
		<QueryClientProvider client={queryClient}>
			<RainbowKitProvider>
				<App />
			</RainbowKitProvider>
		</QueryClientProvider>
	</WagmiProvider>,
	document.getElementById('root'),
);
