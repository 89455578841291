import { useEffect, useState } from "react";
import SmallRect from "./smallRect";

// Format each part to have at least two digits
const formatNumber = (number) => number.toString().padStart(2, '0');

function getTimeComponentsFromTimestamp(timestamp) {
    const seconds = timestamp;
    const days = Math.floor(seconds / (3600 * 24));
    const hours = Math.floor((seconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    return {
        days: formatNumber(days),
        hours: formatNumber(hours),
        minutes: formatNumber(minutes),
        seconds: formatNumber(remainingSeconds)
    };
}

const dateFormat = (count) => {
    if (count <= 0) {
        count = 0;
    }
    const formatTime = getTimeComponentsFromTimestamp(count);
    return formatTime;
};

export default function CountDown({ startTime, endTime }) {
    const [showRestTime, setShowRestTime] = useState({
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00"
    });

    useEffect(() => {
        let intV = -1;
        let ctimer = 0;
        if (startTime > Math.floor(Date.now() / 1000)) {
            ctimer = startTime - Math.floor(Date.now() / 1000);
            setShowRestTime(dateFormat(ctimer));
            intV = setInterval(() => setShowRestTime(dateFormat(ctimer = ctimer - 1)), 1000);
            return () => clearInterval(intV);
        } else if (endTime > Math.floor(Date.now() / 1000)) {
            ctimer = endTime - Math.floor(Date.now() / 1000);
            setShowRestTime(dateFormat(ctimer));
            intV = setInterval(() => setShowRestTime(dateFormat(ctimer = ctimer - 1)), 1000);
            return () => clearInterval(intV);
        }
    }, [startTime, endTime]);

    return (
        <div className="flex flex-col w-full justify-between p-2 rounded-[10px]">
            <span className="text-[42px] leading-32 font-bold sm:text-[36px]">
                {"PRESALE "}
                {startTime > Math.floor(Date.now() / 1000)
                    ? "COMING SOON"
                    : endTime > Math.floor(Date.now() / 1000)
                        ? "ALIVE"
                        : "END"}
            </span>
            <div className="text-[32px] sm:text-[20px] flex justify-center items-center gap-[5px]">
                {endTime > Math.floor(Date.now() / 1000) ? (
                    <>
                        <SmallRect value={showRestTime.days} type="D" />
                        <span className="text-[64px] pb-[5px]">:</span>
                        <SmallRect value={showRestTime.hours} type="H" />
                        <span className="text-[64px] pb-[5px] ">:</span>
                        <SmallRect value={showRestTime.minutes} type="M" />
                        <span className="text-[64px] pb-[5px]">:</span>
                        <SmallRect value={showRestTime.seconds} type="S" />
                    </>
                ) : (
                    <span className="text-[40px] leading-32 font-bold sm:text-[20px]">
                        The Round is ended!
                    </span>
                )}
            </div>
        </div>
    );
}
