import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled, { createGlobalStyle } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import Reveal from 'react-awesome-reveal';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import IconButton from '@mui/material/IconButton';
import ReactLoading from "react-loading";
import LoadingButton from '@mui/lab/LoadingButton';
import { toast } from 'react-toastify';
import Clock from '../components/Presale/Clock';
import { fadeIn, fadeInUp, getUTCNow, getUTCDate, numberWithCommas, LoadingSkeleton, isEmpty } from '../components/utils';
import Swal from 'sweetalert2';

import { ethers } from "ethers";
import { useChainId, useAccount, useBalance, useReadContract, useWriteContract, useReadContracts } from "wagmi";
import {
  TEST_NET,
  SOB_ADDRESS_MAIN,
  SOB_ADDRESS_TEST,
  PRESALE_ADDRESS_MAIN,
  PRESALE_ADDRESS_TEST,
} from "../config";

import SobAbi from "../config/abis/Sob.json";
import PresaleAbi from "../config/abis/Presale.json";
import Status from '../components/status';
import CountDown from '../components/countdown';

const GlobalStyles = createGlobalStyle`
  .ico-container {
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    background-size: 100% !important;
    background-position-x: center !important;
    .ico-header {
      max-width: 900px;
      padding: 12px;
      .ico-title {
        font-size: 36px;
        font-family: "Montserrat", Sans-serif;
      }
      .ico-desc {
        font-size: 20px;
      }
    }
    @media only screen and (max-width: 1400px) {
      flex-direction: column;
    }
    @media only screen and (max-width: 768px) {
      padding: 10px;
      .ico-header {
        padding: 20px;
        .ico-title {
          font-size: 28px;
        }
        .ico-desc {
          font-size: 18px;
        }
      }
    }
  }

  .input-token-panel {
    display: flex;
    background-color: transparent;
    flex-direction: column;
    text-align: left;
    gap: 10px;
    width: 45%;
    .input-box {
      border: solid 1px white;
      border-radius: 8px;
      @media only screen and (max-width: 576px) {
        span {
          font-size: 15px !important;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .input-token {
    width: 50%;
    background: transparent;
    outline: none;
    padding: 10px;
    font-size: 20px;
    font-weight: bold;
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;
    span {
      font-size: 18px;
      font-weight: normal;
    }
  }

  .email_input {
    max-width: 300px;
  }

  .presale-info {
    max-width: 900px;
    padding: 0;
    background: rgba(4, 11, 44, 0.85);
    border-radius: 20px;
    border-width: 2px;
    border-color: rgb(1 180 204);
    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  .presale-content {
    max-width: 900px;
    padding: 0;
    background: rgba(4, 11, 44, 0.85);
    border-radius: 20px;
    border-width: 2px;
    border-color: rgb(1 180 204);
    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  .presale-content-card {
    max-width: 900px;
    padding: 0;
    border-radius: 20px;
    border-width: 2px;
    @media only screen and (max-width: 768px) {
      max-width: 100%;
    }
  }

  .presale-inner {
    border-radius: 12px;
    padding: 15px 60px 35px;
    position: relative;
    background: transparent;
    min-height: 200px;
    h3 {
      line-height: 2;
      margin-bottom: 0;
    }
    @media only screen and (max-width: 1024px) {
      padding: 60px 40px 40px;
    }
    @media only screen and (max-width: 768px) {
      padding: 0px 10px 40px;
    }
  }

  .presale-bg {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 76px;
  }

  .end-content {
    background: #2d81e2;
    padding: 16px;
    border-radius: 40px;
    width: 80%;
    margin: auto;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .buy_content {
    padding: 22px;
    border: solid 2px #bab1b6;
    border-radius: 20px;
  }

  .progress-bg {
    @media only screen and (max-width: 576px) {
      width: 60%;
    }
  }

  .inverstors {
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }

  .amount_bar_text {
    display: flex;
    justify-content: space-between;
  }

  .progress {
    height: 1.5rem;
    background-color: #a9a9a9;
  }

  .progress-bar {
    background-color: #7621ff;
  }

  .MuiLoadingButton-root {
    transition: all 0.5s ease;
  }

  .MuiLoadingButton-loading {
    padding-right: 40px;
    background: linear-gradient(90deg, #aa2d78 -3.88%, #a657ae 100%);
    color: rgb(255 255 255 / 50%) !important;
    transition: all 0.5s ease;
  }
  .swal2-popup {
    border-radius: 20px;
    background: #2f2179;
    color: white;
  }
  .swal2-styled.swal2-confirm {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .backdrop-loading {
  }
  
  .btn-change {
    width: 40px;
    height: 40px;
    background-color: #8b86a4 !important;
    border-radius: 50%;
    margin-bottom: 8px !important;
    color: white !important;
    &:hover {
      background-color: #8b86a4 !important;
    }
  }

  .presale-input {
    align-items: end;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      gap: 10px;
    }
  }
`;

const Loading = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
`;

function CapBackGradientSVG() {
  const gradientTransform = `rotate(0)`;
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={"capBack"} gradientTransform={gradientTransform}>
          <stop offset="0%" stopColor="rgba(236, 0, 140, 0.5)" />
          <stop offset="90%" stopColor="rgba(252, 103, 103, 0)" />
        </linearGradient>
      </defs>
    </svg>
  );
}

function CapGradientSVG() {
  const gradientTransform = `rotate(0)`;
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={"cap"} gradientTransform={gradientTransform}>
          <stop offset="0%" stopColor="#EC008C" />
          <stop offset="100%" stopColor="#FC6767" />
        </linearGradient>
      </defs>
    </svg>
  );
}

function AmountBackGradientSVG() {
  const gradientTransform = `rotate(0)`;
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={"amountBack"} gradientTransform={gradientTransform}>
          <stop offset="0%" stopColor="rgba(5, 117, 230, 0)" />
          <stop offset="90%" stopColor="rgba(2, 242, 149, 0.61)" />
        </linearGradient>
      </defs>
    </svg>
  );
}

function AmountGradientSVG() {
  const gradientTransform = `rotate(0)`;
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={"amount"} gradientTransform={gradientTransform}>
          <stop offset="0" stopColor="#00F260" />
          <stop offset="23.1%" stopColor="#01D97B" />
          <stop offset="73.02%" stopColor="#0581a0" />
          <stop offset="99.02" stopColor="#0575E6" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const Presale = (props) => {
  const chainId = useChainId();
  const account = useAccount();
  const { data: balance, refetch: refetchBalance } = useBalance({ address: account.address });
  const { data: txResult, writeContractAsync } = useWriteContract();

  const { data, refetch } = useReadContracts({
    contracts: [{
      address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
      abi: PresaleAbi,
      functionName: 'totalBuyAmount',
      args: [],
      chainId: chainId,
    }, {
      address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
      abi: PresaleAbi,
      functionName: 'totalClaimAmount',
      args: [],
      chainId: chainId,
    }, {
      address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
      abi: PresaleAbi,
      functionName: 'totalETHAmount',
      args: [],
      chainId: chainId,
    }, {
      address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
      abi: PresaleAbi,
      functionName: 'totalUSDAmount',
      args: [],
      chainId: chainId,
    }, {
      address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
      abi: PresaleAbi,
      functionName: 'startTime',
      args: [],
      chainId: chainId,
    }, {
      address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
      abi: PresaleAbi,
      functionName: 'endTime',
      args: [],
      chainId: chainId,
    }, {
      address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
      abi: PresaleAbi,
      functionName: 'priceInUSD',
      args: [],
      chainId: chainId,
    }, {
      address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
      abi: PresaleAbi,
      functionName: 'priceDenom',
      args: [],
      chainId: chainId,
    }, {
      address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
      abi: PresaleAbi,
      functionName: 'dDecimals',
      args: [],
      chainId: chainId,
    }, {
      address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
      abi: PresaleAbi,
      functionName: 'dDecimalsDenom',
      args: [],
      chainId: chainId,
    }, {
      address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
      abi: PresaleAbi,
      functionName: 'getLatestETHPrice',
      args: [],
      chainId: chainId,
    }]
  })

  const { data: accountData, refetch: accountRefresh } = useReadContracts({
    contracts: [{
      address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
      abi: PresaleAbi,
      functionName: 'buyAmount',
      args: [account.address],
      chainId: chainId,
    }, {
      address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
      abi: PresaleAbi,
      functionName: 'claimAmount',
      args: [account.address],
      chainId: chainId,
    }, {
      address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
      abi: PresaleAbi,
      functionName: 'userPaidInETH',
      args: [account.address],
      chainId: chainId,
    }]
  })

  useEffect(() => {
    accountRefresh()
  }, [account, chainId, accountRefresh])

  const { data: tokenPrice, refetch: refetchTokenPrice } = useReadContract({
    address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
    abi: PresaleAbi,
    functionName: "priceInUSD",
    args: [],
    chainId: chainId,
  });

  const { data: priceDenom, refetch: refetchTokenPriceDenom } = useReadContract({
    address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
    abi: PresaleAbi,
    functionName: "priceDenom",
    args: [],
    chainId: chainId,
  });

  const { data: ethPrice, refetch: refetchEthPrice } = useReadContract({
    address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
    abi: PresaleAbi,
    functionName: "getLatestETHPrice",
    args: [],
    chainId: chainId,
  });

  const { data: startTime, refetch: refetchStartTime } = useReadContract({
    address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
    abi: PresaleAbi,
    functionName: "startTime",
    args: [],
    chainId: chainId,
  });

  const { data: endTime, refetch: refetchEndTime } = useReadContract({
    address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
    abi: PresaleAbi,
    functionName: "endTime",
    args: [],
    chainId: chainId,
  });

  const isMobile = useMediaQuery({ maxWidth: '768px' });
  // const [amountPercent, setAmountPercent] = useState(0);
  // const [curPresale, setCurPresale] = useState('');
  // const [capPercent, setCapPercent] = useState('');
  // const [maxCap, setMaxCap] = useState(0); // USD
  // const [minCap, setMinCap] = useState(0); // USDT
  // const [maxTotalCap, setMaxTotalCap] = useState(''); // USDT
  const [leftCap, setLeftCap] = useState('');
  const [paidUSDT, setPaidUSDT] = useState(0); // USDT
  const [pSobAmount, setPSobAmount] = useState(0);
  const [toUSDPrice, settoUSDPrice] = useState(0);
  // const [maxETHCap, setmaxETHCap] = useState('');
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);

  const [ethAmount, setEthAmount] = useState('');
  // const [toBalance, setToBalance] = useState(0);
  const [ended, setEnded] = useState(false);

  const handleChange = async (event) => {
    const value = Number(event.target.value);
    if (value < 0) {
      setEthAmount('0');
    } else {
      setEthAmount(event.target.value);

      if (value === 0) {
        setPSobAmount(0);
        settoUSDPrice(0);
        return;
      }

      const usdAmount = Number(ethers.formatUnits(ethPrice, 8)) * value
      settoUSDPrice(usdAmount);
      setPSobAmount(usdAmount * Number(priceDenom) / Number(tokenPrice));
    }
  }

  const validate = () => {
    if (isEmpty(ethAmount) || Number(ethAmount) === 0) {
      toast.error("Please enter a valid amount for purchase.");
      return;
    }

    if (Number(balance.ethBalance) < Number(ethAmount)) {
      toast.error("You have insufficient amount to buy SoB.");
      return false;
    }
    if (Number(startTime) * 1000 > getUTCNow()) {
      toast.error("Presale has not started yet.");
      return false;
    } else if (Number(endTime) * 1000 < getUTCNow()) {
      toast.error("Presale has ended.");
      return false;
    }
    return true;
  }

  const handleMax = async () => {
    const value = (balance ? Number(ethers.formatUnits(balance.value.toString(), 18)) : 0) - 0.0001;
    if (value < 0) {
      setEthAmount(0);
      settoUSDPrice(0);
      setPSobAmount(0);
    } else {
      setEthAmount(value);
      const usdAmount = Number(ethers.formatUnits(ethPrice, 8)) * value
      settoUSDPrice(usdAmount);
      setPSobAmount(usdAmount * Number(priceDenom) / Number(tokenPrice));
    }
  }

  const handleBuy = async () => {
    if (!validate()) return;
    setPending(true);
    await writeContractAsync({
      address: chainId === TEST_NET ? PRESALE_ADDRESS_TEST : PRESALE_ADDRESS_MAIN,
      abi: PresaleAbi,
      functionName: "buyTokensByETH",
      args: [],
      value: ethers.parseEther(ethAmount.toString(), 18)
    })
      .then(() => {
        refetchBalance();
        refetch();
        accountRefresh();
        toast.success("Buy Submitted!");
        setPending(false);
      })
      .catch((err) => {
        setPending(false);
      });
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);

  useEffect(() => {
    // const checkEndPresale = async () => {
    //   const result = await getEndPresaleTime();
    //   if (result.success) {
    //     setEndTime(result.end_time);
    //   }
    // }
    // if (ended) {
    //   checkEndPresale();
    // }
  }, [ended]);

  return (
    <div className='page-container text-center ico-container'>
      <GlobalStyles />
      <div className='ico-header'>
        <Reveal className='onStep' keyframes={fadeInUp} delay={0} duration={600} triggerOnce>
          <p className='ico-title'>Welcome to the SoB Presale</p>
        </Reveal>
        {/* <Reveal className='onStep' keyframes={fadeInUp} delay={300} duration={600} triggerOnce>
          <p className="ico-desc">
            Presale term - {getUTCDate(startTime)} - {getUTCDate(endTime)} <br /> You can participate using ETH.
          </p>
        </Reveal> */}
        <Reveal className='onStep' keyframes={fadeInUp} delay={600} duration={600} triggerOnce>
          <p className="ico-desc">
            SoB Token CA: <a href="https://explorer.zksync.io/address/0x814f0a57aeb46c951FCD15B24399620fa366D002#contract" target='_blank' rel='noreferrer' style={{ color: 'rgb(13 255 255)', fontFamily: "Lato, Sans-serif", fontSize: "18px", fontWeight: "400" }}>
              {isMobile ? "0x814f0a...66D002" : "0x814f0a57aeb46c951FCD15B24399620fa366D002"}
            </a>
          </p>
        </Reveal>
      </div>
      {loading ? (
        <div className='backdrop-loading'>
          <Loading className='loading'>
            <ReactLoading type={'spinningBubbles'} color="#fff" />
          </Loading>
        </div>
      ) : (
        <>
          <Reveal className='onStep lg:w-4/5 w-11/12' keyframes={fadeIn} delay={800} duration={800} triggerOnce>
            <Status
              data={data}
              accountData={accountData}
            />
            {false ? <section className='presale-content'>
              <div className='presale-inner pt-3 pb-4'>
                <div className="row">
                  <div className="flex justify-content-between mb-3">
                    <div className='flex flex-column flex-md-row gap-2 '>
                      <span>Start Time: </span>
                      <span>{startTime === 0 ? <LoadingSkeleton /> : getUTCDate(startTime)}</span>
                    </div>
                    <div className='flex flex-column flex-md-row gap-2'>
                      <span>End Time: </span>
                      <span>{endTime === 0 ? <LoadingSkeleton /> : getUTCDate(endTime)}</span>
                    </div>
                  </div>
                  {leftCap !== '' && (
                    <>
                      {getUTCNow() / 1000 >= endTime || leftCap <= 0 ? (
                        <>
                          <div className='end-content'>
                            <span className='fs-20 fw-bold'>PRESALE HAS ENDED</span>
                          </div>
                          <p className='fs-20 fw-bold mt-3 mb-1 text-gray'>Your Holdings (SoB): <span className='text-white'>{paidUSDT === '' ? <LoadingSkeleton /> : numberWithCommas(paidUSDT / Number(tokenPrice))}</span></p>
                        </>
                      ) : (
                        <>
                          <div className='col-md-4 align-self-center'>
                            <h3>TIME REMAINING TO PARTICIPATE IN PRESALE</h3>
                          </div>
                          <div className="col-md-8 align-self-center">
                            <Clock deadline={endTime * 1000} setEnded={(value) => setEnded(value)} />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </section> : <></>}
          </Reveal>
          {true && (
            <Reveal className='presale-content-card main mt-10 onStep border-white bg-[green]/60' keyframes={fadeIn} delay={800} duration={800} triggerOnce>
              <div className='presale-inner'>
                <CountDown
                  startTime={data && data[4].status === 'success' ? parseInt(data[4].result) : 0}
                  endTime={data && data[5].status === 'success' ? parseInt(data[5].result) : 0} />
                <div className="row justify-center">
                  {/* <div className="col-md-6 col-sm-6 mt-5">
                    <div className="amount_bar px-3">
                      <h3>Presale Amount received</h3>
                      <div className='progress-bg m-auto'>
                        <AmountBackGradientSVG />
                        <AmountGradientSVG />
                        <CircularProgressbar
                          value={amountPercent}
                          text={`${numberWithCommas(amountPercent, 2)}%`}
                          styles={buildStyles({
                            pathColor: `url(#amount)`,
                            textColor: '#00DB8B',
                            strokeLinecap: "butt",
                            trailColor: `url(#amountBack)`
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 mt-5">
                    <div className="amount_bar px-3">
                      <h3>Your Hard Cap Amount</h3>
                      <div className='progress-bg m-auto'>
                        <CapBackGradientSVG />
                        <CapGradientSVG />
                        <CircularProgressbar
                          value={capPercent}
                          text={`${numberWithCommas(capPercent, 2)}%`}
                          styles={buildStyles({
                            pathColor: `url(#cap)`,
                            textColor: '#EF1485',
                            strokeLinecap: "butt",
                            trailColor: `url(#capBack)`
                          })}
                        />
                      </div>
                    </div>
                  </div> */}
                  {false ? <div className='col-md-12 mt-3'>
                    {isMobile ? (
                      <>
                        {/* <p className='fs-20 mb-1'>Presale Amount received <br /><strong>{curPresale === '' ? <LoadingSkeleton /> : '$' + numberWithCommas(curPresale) + ' USDT'}</strong></p>
                        <p className='fs-20 mb-1'>Maximum Presale Amount Allocated <br /><strong>{tokenPrice === '' ? <LoadingSkeleton /> : '$' + numberWithCommas(max_token_amount * Number(tokenPrice)) + ' USDT'}</strong></p> */}
                        <p className='fs-20 mb-1'>SoB Price <br /><strong>{!tokenPrice ? <LoadingSkeleton /> : ethers.formatUnits(tokenPrice.toString(), 8) + ' USD'}</strong></p>
                      </>
                    ) : (
                      <>
                        {/* <p className='fs-20 mb-1'>Presale Amount received : <strong>{curPresale === '' ? <LoadingSkeleton /> : '$' + numberWithCommas(curPresale) + ' USDT'}</strong></p>
                        <p className='fs-20 mb-1'>Maximum Presale Amount Allocated : <strong>{tokenPrice === '' ? <LoadingSkeleton /> : '$' + numberWithCommas(max_token_amount * Number(tokenPrice)) + ' USDT'}</strong></p> */}
                        <p className='fs-20 mb-1'>SoB Price : <strong>{!tokenPrice || !priceDenom ? <LoadingSkeleton /> : (Number(tokenPrice) / Number(priceDenom)).toFixed(1) + ' USD'}</strong></p>
                      </>
                    )}
                  </div> : <></>}
                  {/* {(leftCap > 0) && (
                    <>
                      {maxTotalCap <= paidUSDT && false ? (
                        <div className='buy_content'>
                          <p className='fs-20 mb-1'>You have got the maximum SoB on presale.</p>
                          <p className='fs-20 mb-1'>Your Holdings (SoB): {paidUSDT === '' ? <LoadingSkeleton /> : numberWithCommas(paidUSDT / Number(tokenPrice))}</p>
                        </div>
                      ) : ( */}
                  <div className='col-md-12 mt-3'>
                    <div className='buy_content'>
                      <div className='row'>
                        <div className='col-md-12'>
                          <p className='fs-20'>Please enter the ETH amount you'd like to purchase</p>
                          <div className='presale-input flex'>
                            <div className="input-token-panel">
                              <div className='flex justify-between'>
                                <label className="fs-20">From</label>
                                <span className='fs-20'>Balance: {balance ? Number(ethers.formatUnits(balance.value.toString(), 18)).toPrecision(3) : 0}</span>
                              </div>
                              <div className="d-flex justify-content-between input-box">
                                <input type="number" className="input-token" name="input_from" placeholder='0.0' value={ethAmount} onChange={handleChange}></input>
                                <button className='btn-max swap-color' onClick={handleMax}>MAX</button>
                                {/* <SelectCoin className='select-coin' value={coinType} onChange={handleSelectCoin}  /> */}
                                <div className='flex align-items-center gap-2' style={{ padding: '10px' }}>
                                  <img
                                    loading="lazy"
                                    width="35"
                                    height="35"
                                    src={`/img/icons/eth.png`}
                                    alt={`Coin of SoB`}
                                  />
                                  <span className='fs-20'>{'ETH'}</span>
                                </div>
                              </div>
                            </div>
                            <IconButton component="span" className="btn-change mx-auto">
                              {isMobile ? (
                                <i className="fa-solid fa-arrow-down"></i>
                              ) : (
                                <i className="fa-solid fa-arrow-right"></i>
                              )}
                            </IconButton>
                            <div className="input-token-panel">
                              <div className='flex justify-between'>
                                <label className="fs-20">To</label>
                                {/* <span className='fs-20'>Balance: {numberWithCommas(Number(toBalance))}</span> */}
                              </div>
                              <div className="d-flex justify-content-between input-box">
                                <p className="input-token mb-0">{numberWithCommas(pSobAmount, 1)}&nbsp;
                                  {/* coinType === 0 && */(
                                    <span>{toUSDPrice === 0 ? '' : ' ($' + numberWithCommas(Number(toUSDPrice), 2) + ')'}</span>
                                  )}
                                </p>
                                <div className='flex align-items-center gap-2' style={{ padding: '10px' }}>
                                  <img
                                    loading="lazy"
                                    width="35"
                                    height="35"
                                    src={`/img/icons/token_icon.png`}
                                    alt={`Coin of SoB`}
                                  />
                                  <span className='fs-20'>{'SoB'}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className='col-md-12 mt-3'>
                          <LoadingButton
                            onClick={handleBuy}
                            endIcon={<></>}
                            loading={pending}
                            loadingPosition="end"
                            variant="contained"
                            className="btn-buy btn-main btn3 m-auto fs-20"
                          >
                            BUY SoB
                          </LoadingButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Reveal>
          )}
        </>
      )}
    </div >
  );
};

export default Presale;    