const SmallRect = (props) => {
    return (
        <div className={`w-[80px] h-[80px] font-bold border-white border-[2px] rounded-[10px] flex items-center flex-col justify-center sm:font-normal shadow-lg shadow-[#A17948]`}>
            <div className="text-[40px] leading-none text-white sm:text-[42px] animation-pulse">{props.value}</div>
            <div className="text-[18px] text-white sm:text-[22px] sm:pb-0">{props.type}</div>
        </div>
    );
}

export default SmallRect;
