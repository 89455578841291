import React, { useEffect, useCallback } from "react";
import { useMediaQuery } from 'react-responsive';
import ConnectBtn from "../ConnectBtn";
import {
  CloseButtonIcon,
  HamburgerIcon,
} from "../../assets/constants";

const Header = function ({ navSelected, setIsOpen }) {
  const isMobile = useMediaQuery({ maxWidth: '1024px' });

  return (
    <div className='text-gray-100 flex justify-between bg-[green]/60 border-b-4 border-[#356e29] lg:h-[100px] h-[200px] w-full z-[100] fixed'>
      <div className='flex justify-between z-index-9 p-3 my-auto py-auto'>
        <a className='pl-[30px]' href="https://sobnfts.club" target="_blank" rel="noreferrer">
          <img src="/img/logo.png" className='h-[80px]' alt="" />
        </a>
      </div>
      <div className="pr-6 my-auto py-auto text-xl">
        <ConnectBtn />
      </div>
    </div >
  )
}
export default Header;