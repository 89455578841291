import { ethers } from "ethers";
import { LoadingSkeleton } from "./utils";
import { useMediaQuery } from "react-responsive";

function formatNumber(num) {
    if (num >= 1e12) {
        return (num / 1e12).toFixed(2) + 'T';
    } else if (num >= 1e9) {
        return (num / 1e9).toFixed(2) + 'B';
    } else if (num >= 1e6) {
        return (num / 1e6).toFixed(2) + 'M';
    } else if (num >= 1e3) {
        return (num / 1e3).toFixed(2) + 'K';
    } else {
        return num.toFixed(2);
    }
}

export default function Status({ data, accountData }) {
    const isMobile = useMediaQuery({ maxWidth: '768px' });

    return (
        <div className="w-full mx-auto gap-0 lg:gap-4 flex lg:flex-row flex-col items-center justify-between text-center px-1 py-[10px] lg:py-[30px] rounded-3xl border-2 border-[rgb(1 180 204)] bg-[green]/60">
            <div className="text-xl text-white lg:w-1/5 w-full flex lg:flex-col flex-row lg:justify-center justify-between items-center text-center px-2 lg:py-3 py-1">
                <div className={`w-full items-center text-center px-2 lg:py-3 py-1 ${isMobile ? 'flex flex-row justify-between' : ''}`}>
                    <label className="font-semibold text-gray-50 text-lg px-2">Total Raised</label>
                    <label className="text-2xl sm:text-3xl font-bold block bg-clip-text">
                        {data && data[2]?.status === 'success' ? `${Number(ethers.formatUnits(data[2].result, 18)).toFixed(3)} ETH` : <LoadingSkeleton />}
                    </label>
                </div>
            </div>
            <div className="text-xl text-white lg:w-1/5 w-full flex lg:flex-col flex-row lg:justify-center justify-between items-center text-center px-2 lg:py-3 py-1">
                <div className={`w-full items-center text-center px-2 lg:py-3 py-1 ${isMobile ? 'flex flex-row justify-between' : ''}`}>
                    <label className="font-semibold text-gray-50 text-lg px-2">SoB Price</label>
                    <label className="text-2xl sm:text-3xl font-bold block bg-clip-text">
                        {data && data[6]?.status === 'success' && data[7]?.status === 'success' ? `${(Number(data[6]?.result) / Number(data[7]?.result)).toFixed(1)} USD` : <LoadingSkeleton />}
                    </label>
                </div>
            </div>
            <div className="text-xl text-white lg:w-1/5 w-full flex lg:flex-col flex-row lg:justify-center justify-between items-center text-center px-2 lg:py-3 py-1">
                <div className={`w-full items-center text-center px-2 lg:py-3 py-1 ${isMobile ? 'flex flex-row justify-between' : ''}`}>
                    <label className="font-semibold text-gray-50 text-lg px-2">Total Sold</label>
                    <label className="text-2xl sm:text-3xl font-bold block bg-clip-text">
                        {data && data[0]?.status === 'success' ? `${Number(ethers.formatUnits(data[0].result, 18)).toFixed(2)} SoB` : <LoadingSkeleton />}
                    </label>
                </div>
            </div>
            <div className="text-xl text-white lg:w-1/5 w-full flex lg:flex-col flex-row lg:justify-center justify-between items-center text-center px-2 lg:py-3 py-1">
                <div className={`w-full items-center text-center px-2 lg:py-3 py-1 ${isMobile ? 'flex flex-row justify-between' : ''}`}>
                    <label className="font-semibold text-gray-50 text-lg px-2">Your Alloc</label>
                    <label className="text-2xl sm:text-3xl font-bold block bg-clip-text">
                        {accountData && accountData[0]?.status === 'success' ? `${Number(ethers.formatUnits(accountData[0].result, 18)).toFixed(2)} SoB` : <LoadingSkeleton />}
                    </label>
                </div>
            </div>
        </div>
    );
}
